// Here you can add other styles

/*
.breadcrumb {
  margin-bottom: 0.1rem !important;
}

.card {
  margin-bottom: 0.1rem !important;
}

.container-fluid {
  padding-right: 0.1rem !important;
  padding-left: 0.1rem !important;
}*/

.sidebar
{
  background: #173963 !important;
}

.sidebar {
  .nav-link:hover {
    background: #50b848 !important;
  }
}

.btn-success {
  color: #fff;
  background-color: #50b848 !important;
  border-color: #50b848 !important;
}

[class*="MuiInput-underline"] {

  &:after {
    border-bottom: 2px solid #173963 !important;
  }
}

.app-header
{
  .nav-item {
    min-width: 40px !important;
  }
}

.breadcrumb
{
  margin-bottom: 0.5rem !important;
}

.card
{
  margin-bottom: 0.5rem !important;
}

.main .container-fluid
{
   padding: 0 15px !important;
}


.btn-info
{
  color: #FFFFFF !important;
}
